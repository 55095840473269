import { Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";
import React, { ReactNode } from "react";

const ProCard = ({
  title,
  subtitle,
  desc,
  link,
  linkalt,
  component,
  backgroundColor,
  onClick,
  image,
  imagealt,
}: {
  image?: string;
  imagealt?: string;
  backgroundColor?: string;
  component?: ReactNode;
  linkalt?: string;
  title?: string;
  subtitle?: string;
  desc?: string;
  link?: string;
  onClick?: () => void;
}) => {
  return (
    <a
      rel="noreferrer"
      aria-label={linkalt}
      target="_blank"
      href={link}
      style={{ textDecoration: `none`, color: "inherit" }}
    >
      <Stack
        direction="column"
        spacing={1}
        mb={3}
        component={motion.div}
        initial={{ y: 0 }}
        whileHover={{
          y: -10,
        }}
      >
        <img
          alt={imagealt}
          src={image}
          style={{
            width: 30,
            height: 30,
            borderRadius: 6,
          }}
        />
        <Typography variant="body2">{title}</Typography>
        <Typography variant="body2" color="text.secondary">
          {desc}
        </Typography>
      </Stack>
    </a>
  );
};

export default ProCard;
