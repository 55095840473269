import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "aos/dist/aos.css";
import HeaderImage from "../components/LandingRelated/HeaderImage";
import "../index.css";
import React from "react";
import LandingLeft from "../components/LandingRelated/LandingLeft";

export const Landing = () => {
  const siteStyles = {
    width: "100%",
    height: "100dvh",
    overflowX: "hidden",
    "&::-webkit-scrollbar": { width: 0 },
  };
  const theme = useTheme();
  const isntMobile = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Grid
      id="home"
      component="section"
      display="flex"
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      container
      sx={siteStyles}
    >
      <LandingLeft />
      {isntMobile ? <HeaderImage /> : null}
    </Grid>
  );
};
