import {
  Box,
  Chip,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { ReactNode } from "react";

const AllNighterCard = ({
  name,
  subtitle,
  desc,
  link,
  linkalt,
  component,
  backgroundColor,
  onClick,
}: {
  backgroundColor?: string;
  component?: ReactNode;
  linkalt?: string;
  name?: string;
  subtitle?: string;
  desc?: string;
  link?: string;
  onClick?: () => void;
}) => {
  const theme = useTheme();
  const isntMobile = useMediaQuery(theme.breakpoints.up("md"));
  const borderStyles = {
    boxShadow: "0px 0px 0px 1px rgba(0,0,0,0.3)",
    borderRadius: "12px",
    mixBlendMode: "color-burn",
    p: 1,
  };
  const cardStyles = {
    borderRadius: "12px",
    backgroundColor: backgroundColor,
  };

  return (
    <Box sx={cardStyles}>
      <Grid
        container
        sx={borderStyles}
        height={200}
        justifyContent="space-between"
      >
        <Grid item md={4}>
          <Stack direction="column" spacing={2} p={1}>
            <Typography variant="body2">{subtitle}</Typography>
            <Stack>
              <Typography variant="h5">{name}</Typography>
              <Typography variant="subtitle2">{desc}</Typography>
            </Stack>
            <a
              rel="noreferrer"
              aria-label={linkalt}
              target="_blank"
              href={link}
              style={{ textDecoration: `none` }}
            >
              <Chip
                variant="outlined"
                onClick={onClick ? onClick : () => {}}
                label={<Typography variant="caption">View More</Typography>}
                sx={{
                  cursor: "pointer",
                  width: "fit-content",
                }}
              />
            </a>
          </Stack>
        </Grid>
        {isntMobile ? component : null}
      </Grid>
    </Box>
  );
};

export default AllNighterCard;
