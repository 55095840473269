import { createTheme } from "@mui/material/styles";

const primaryColors = {
  primary: `#fefaf6`,
  light: `#f8f8f8`,
  accent: `#ffc4ff`,
  accentBackground: `rgba(136, 107, 136, 0.3)`,
  grey: `#808080`,
  text: `#232320`,
  textSecondary: `#74717d`,
  greyLight: `#f8f8f8`,
};

export const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    primary: {
      main: primaryColors.primary,
      light: primaryColors.greyLight,
    },
    secondary: {
      main: primaryColors.light,
      light: primaryColors.grey,
    },
    info: {
      main: primaryColors.accent,
      light: primaryColors.accentBackground,
    },
    text: {
      primary: primaryColors.text,
      secondary: primaryColors.grey,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          outline: "solid",
          outlineColor: primaryColors.grey,
          transition: "0.8s",
          "&:hover": {
            outlineColor: primaryColors.accent,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderColor: primaryColors.greyLight,
          borderRadius: 10,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 14,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(198, 194, 191,0.9)",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          zIndex: 1,
          backgroundColor: primaryColors.primary,
          color: "#000000",
          borderRadius: 5,
          transition: "0.8s",
          "&:hover": {
            backgroundColor: primaryColors.accent,
          },
        },
        label: {},
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: primaryColors.primary,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 14,
          height: 14,
          transition: "0.3s",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        button: {
          outlineColor: primaryColors.greyLight,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {},
      },
    },
  },
});

theme.typography.h1 = {
  color: primaryColors.text,
  fontSize: "4rem",
  fontWeight: 500,
  "@media (max-width:1536px)": {
    fontSize: "3rem",
  },
  "@media (max-width:1200px)": {
    fontSize: "3rem",
  },
  "@media (max-width:900px)": {
    fontSize: "2rem",
  },
  "@media (max-width:600px)": {
    fontSize: "1rem",
  },
  "@media (max-width:390px)": {
    fontSize: "2rem",
  },
};
theme.typography.h2 = {
  color: primaryColors.text,
  fontWeight: 500,
  fontSize: "2.5rem",
  "@media (max-width:1536px)": {
    fontSize: "2.5rem",
  },
  "@media (max-width:1200px)": {
    fontSize: "2rem",
  },
  "@media (max-width:900px)": {
    fontSize: "1rem",
  },
  "@media (max-width:600px)": {
    fontSize: "2rem",
  },
};
theme.typography.h3 = {
  fontSize: `1.563rem`,
  fontWeight: 300,
};
theme.typography.h4 = {
  fontSize: `28px`,
  fontWeight: 400,
};
theme.typography.h6 = {
  fontSize: `1rem`,
  fontWeight: 400,
  "@media (max-width:1536px)": {
    fontSize: "1rem",
  },
  "@media (max-width:1200px)": {
    fontSize: "1rem",
  },
  "@media (max-width:900px)": {
    fontSize: "1rem",
  },
  "@media (max-width:600px)": {
    fontSize: "0.9rem",
  },
};
theme.typography.subtitle1 = {
  transition: "0.3s linear",
  color: primaryColors.grey,
  fontSize: "1.125rem",
  fontWeight: 500,
  "@media (max-width:1536px)": {
    fontSize: "1rem",
  },
  "@media (max-width:1200px)": {
    fontSize: "1rem",
  },
  "@media (max-width:900px)": {
    fontSize: "1rem",
  },
  "@media (max-width:600px)": {
    lineHeight: 1.7,
    fontSize: "0.8rem",
  },
  "@media (max-width:390px)": {
    lineHeight: 1.7,
    fontSize: "0.8rem",
  },
};
theme.typography.subtitle2 = {
  color: primaryColors.grey,
  fontWeight: 600,
  fontSize: "0.7rem",
  "@media (max-width:1536px)": {
    fontSize: "0.7rem",
  },
};
theme.typography.button = {
  color: primaryColors.grey,
  fontWeight: 600,
  fontSize: "1.25rem",
  "@media (max-width:1536px)": {
    fontSize: "1.25rem",
  },
  "@media (max-width:1200px)": {
    fontSize: "1.25",
  },
  "@media (max-width:900px)": {
    fontSize: "1.25",
  },
  "@media (max-width:600px)": {
    lineHeight: 1.7,
    fontSize: "0.8rem",
  },
  "@media (max-width:390px)": {
    lineHeight: 1.7,
    fontSize: "0.8rem",
  },
};
