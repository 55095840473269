import { Grid, Typography, Stack } from "@mui/material";
import React from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

const Random = () => {
  return (
    <Grid
      container
      item
      xl={8}
      spacing={4}
      component="div"
      data-aos="zoom-out-up"
    >
      <Grid item xl={12}>
        <Typography variant="body2">
          Will figure out what to put here
        </Typography>
      </Grid>
      <Grid item xl={12}>
        <Typography variant="subtitle1">Bucket List!</Typography>
      </Grid>
      <Grid item xl={8} direction="column" spacing={4}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <CheckCircleOutlinedIcon sx={{ color: "#4BB543" }} />
          <Typography variant="body2">Joined a 9Man Volleyball Team</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <CheckCircleOutlinedIcon sx={{ color: "#4BB543" }} />
          <Typography variant="body2">Got a tattoo</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <CheckCircleOutlinedIcon sx={{ color: "#4BB543" }} />
          <Typography variant="body2">Visited Japan</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <CheckCircleOutlinedIcon sx={{ color: "#4BB543" }} />
          <Typography variant="body2">Visited San Diego</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <CheckCircleOutlinedIcon sx={{ color: "#4BB543" }} />
          <Typography variant="body2">Visited Vegas</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <CheckCircleOutlinedIcon sx={{ color: "#4BB543" }} />
          <Typography variant="body2">Pickleball</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Random;
