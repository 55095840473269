import { Grid } from "@mui/material";
import React from "react";
import { motion } from "framer-motion";

const HeaderImage = () => {
  return (
    <Grid item xl={3} lg={3} md={3} sm={5} ml={1} component="figure" mr={"3vw"}>
      <motion.div
        initial={{ opacity: 0, scale: 1.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.6 }}
      >
        <motion.img
          animate={{ y: [30, 0, 30] }}
          transition={{ delay: 1, repeat: Infinity, duration: 2 }}
          style={{
            objectFit: "contain",
          }}
          src="./assets/RobotPC-min.png"
          width={"150%"}
          height={"auto"}
          alt="A 3D robot blender image using a macbook with stickers from figma and other logos, animated with Framer Motion to produce a floating effect. The asset is from a 3D Illustration pack for free Assets made by SALY."
        />
      </motion.div>
    </Grid>
  );
};

export default HeaderImage;
