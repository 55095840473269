import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
const LandingContactText = () => {
  const [emailHover, setEmailHover] = useState(false);
  const [githubHover, setGithubHover] = useState(false);
  const [linkedinHover, setLinkedinHover] = useState(false);
  const [readCvHover, setReadCvHover] = useState(false);

  return (
    <Stack
      spacing={2}
      component="div"
      data-aos="fade-up"
      data-aos-once="true"
      data-aos-duration="1250"
      width={120}
    >
      <Typography
        variant="h6"
        component="div"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-duration="1400"
      >
        Contact
      </Typography>
      <Stack sx={{ cursor: "pointer" }}>
        <Stack
          onMouseEnter={() => setEmailHover(true)}
          onMouseLeave={() => setEmailHover(false)}
          component="div"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-duration="1550"
        >
          <a
            rel="noreferrer"
            aria-label="email"
            target="_blank"
            href="mailto:henrynganwork@gmail.com?subject=Hey!%20I%20wanted%20to%20talk%20to%20you%20about%20..."
            style={{ textDecoration: `none` }}
          >
            <Typography
              variant="subtitle1"
              sx={
                emailHover
                  ? {
                      "&:hover": {
                        transition: "all 0.3s linear",
                        color: "rgba(136, 107, 136, 0.3)",
                      },
                    }
                  : null
              }
            >
              Email{" "}
              <ArrowOutwardIcon
                sx={
                  emailHover
                    ? {
                        transition: "0.3s",
                        transform: "translate(2px,-2px)",
                      }
                    : null
                }
              />
            </Typography>
          </a>
        </Stack>
        <Stack
          onMouseEnter={() => setGithubHover(true)}
          onMouseLeave={() => setGithubHover(false)}
          component="div"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-duration="1600"
        >
          <a
            rel="noreferrer"
            aria-label="github"
            target="_blank"
            href="https://github.com/henryngann"
            style={{ textDecoration: `none` }}
          >
            <Typography
              variant="subtitle1"
              sx={
                githubHover
                  ? {
                      "&:hover": {
                        transition: "all 0.3s linear",
                        color: "rgba(136, 107, 136, 0.3)",
                      },
                    }
                  : null
              }
            >
              Github{" "}
              <ArrowOutwardIcon
                sx={
                  githubHover
                    ? {
                        transition: "0.3s",
                        transform: "translate(2px,-2px)",
                      }
                    : null
                }
              />
            </Typography>
          </a>
        </Stack>
        <Stack
          onMouseEnter={() => setLinkedinHover(true)}
          onMouseLeave={() => setLinkedinHover(false)}
          component="div"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-duration="1650"
        >
          {" "}
          <a
            rel="noreferrer"
            aria-label="linkedin"
            target="_blank"
            href="https://www.linkedin.com/in/henryngan/"
            style={{ textDecoration: `none` }}
          >
            <Typography
              variant="subtitle1"
              sx={
                linkedinHover
                  ? {
                      "&:hover": {
                        transition: "all 0.3s linear",
                        color: "rgba(136, 107, 136, 0.3)",
                      },
                    }
                  : null
              }
            >
              Linkedin{" "}
              <ArrowOutwardIcon
                sx={
                  linkedinHover
                    ? {
                        transition: "0.3s",
                        transform: "translate(2px,-2px)",
                      }
                    : null
                }
              />
            </Typography>
          </a>
        </Stack>
        <Stack
          onMouseEnter={() => setReadCvHover(true)}
          onMouseLeave={() => setReadCvHover(false)}
          component="div"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-duration="1700"
        >
          {" "}
          <a
            rel="noreferrer"
            aria-label="Henry's ReadCv"
            target="_blank"
            href="https://read.cv/henryngann"
            style={{ textDecoration: `none` }}
          >
            <Typography
              variant="subtitle1"
              sx={
                readCvHover
                  ? {
                      "&:hover": {
                        transition: "all 0.3s linear",
                        color: "rgba(136, 107, 136, 0.3)",
                      },
                    }
                  : null
              }
            >
              ReadCV{" "}
              <ArrowOutwardIcon
                sx={
                  readCvHover
                    ? {
                        transition: "0.3s",
                        transform: "translate(2px,-2px)",
                      }
                    : null
                }
              />
            </Typography>
          </a>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LandingContactText;
