import { ThemeProvider } from "@emotion/react";
import "aos/dist/aos.css";
import React from "react";
import { theme } from "../src/style/theme";
import DelayedLoader from "./components/DelayedLoader";
import MainPage2 from "./pages/LoaderPage";

function App() {
  const MainPage = React.lazy(() => {
    return new Promise((resolve) => setTimeout(resolve, 2470)).then(
      () => import("./pages/LoaderPage")
    );
  });

  return (
    <ThemeProvider theme={theme}>
      <MainPage2 />
      {/* <React.Suspense fallback={<DelayedLoader />}>
      
        <MainPage />
      </React.Suspense> */}
    </ThemeProvider>
  );
}

export default App;
