import { Stack, Avatar, Typography } from "@mui/material";
import React from "react";

const LandingNameAvatar = () => {
  return (
    <Stack
      spacing={2}
      component="div"
      data-aos="fade-up"
      data-aos-once="true"
      data-aos-duration="800"
    >
      <Avatar
        alt="Henry Ngan"
        src="./assets/henryprofilepicture.webp"
        sx={{ width: 48, height: 48 }}
      />
      <Typography
        variant="h4"
        component="div"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-duration="850"
      >
        Henry Ngan
      </Typography>
    </Stack>
  );
};

export default LandingNameAvatar;
