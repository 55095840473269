import React from "react";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { Box, Button, Grid, Stack, Tab, Typography } from "@mui/material";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AllNighters from "../components/ProjectRelated/AllNighters";
import Random from "../components/ProjectRelated/Random";
import Professional from "../components/ProjectRelated/Professional";

const ProjectPage5 = () => {
  const siteStyles = {
    width: "100%",
    pt: 2,
  };

  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Stack
      id="Projects"
      component="section"
      display="flex"
      direction="column"
      alignItems="center"
      sx={siteStyles}
    >
      <Link to="/">
        <Button>
          <KeyboardReturnIcon
            sx={(theme) => ({
              width: 50,
              height: 50,
              transition: "0.3s",
              color: theme.palette.text.secondary,
              "&:hover": {
                color: theme.palette.info.light,
              },
            })}
          />
        </Button>
      </Link>
      <Grid
        container
        justifyContent="center"
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <Grid item xl={4} lg={5} md={7} sm={6} xs={10}>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  centered
                  TabIndicatorProps={{ sx: { backgroundColor: "#272727" } }}
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    disableRipple
                    sx={{ borderRadius: 4 }}
                    label={
                      <Typography variant="body2" color="text.primary">
                        Professional
                      </Typography>
                    }
                    value="1"
                  />
                  <Tab
                    disableRipple
                    sx={{ borderRadius: 4 }}
                    label={
                      <Typography variant="body2" color="text.primary">
                        All Nighters
                      </Typography>
                    }
                    value="2"
                  />
                  <Tab
                    disableRipple
                    sx={{ borderRadius: 4 }}
                    label={
                      <Typography variant="body2" color="text.primary">
                        Random
                      </Typography>
                    }
                    value="3"
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Professional />
              </TabPanel>
              <TabPanel value="2">
                <AllNighters />
              </TabPanel>
              <TabPanel value="3">
                <Random />
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ProjectPage5;
