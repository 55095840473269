import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Landing } from "./Landing";
import ProjectPage5 from "./ProjectPage5";

const LoaderPage = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="projects" element={<ProjectPage5 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default LoaderPage;
