import { Box, Grid, Modal, Stack, Typography } from "@mui/material";
import { useState } from "react";
import React from "react";
import AllNighterCard from "./AllNighterCard";

export default function AllNighters() {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 275,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [hover, setHover] = useState(false);
  const [hover2, setHover2] = useState(false);

  return (
    <Stack flexDirection="column">
      <Grid
        container
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        spacing={6}
      >
        <Grid item md={12} component="div" data-aos="zoom-out-up" mt={3}>
          <AllNighterCard
            subtitle="NSBEHacks UofT"
            name="CoLaB"
            link="https://devpost.com/software/colab-qfjwod"
            linkalt="Create and join special workout rooms with friends, coworkers, classes, teams, and other fun groups of people. CoLab makes it easy to follow workouts while connecting you to those that matter."
            desc="A workout platform that won the Best Overall Hack Award."
            component={
              <Box>
                <img
                  alt={"alt"}
                  src="./assets/colabmockup3.webp"
                  onMouseOver={() => setHover2(true)}
                  onMouseLeave={() => setHover2(false)}
                  style={{
                    width: 300,
                    transition: "0.3s",
                    transform: hover2 ? "scale(0.9)" : "scale(1)",
                  }}
                />
              </Box>
            }
          />
        </Grid>
        <Grid item md={12} mb={3} component="div" data-aos="zoom-out-up">
          <AllNighterCard
            subtitle="Smart Coaster"
            name="Torro"
            desc="For tea lovers that want to always drink at an ideal temperature."
            component={
              <Box
                width="50%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  alt={"alt"}
                  src="./assets/torro.png"
                  onMouseOver={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  style={{
                    borderRadius: 10,
                    transition: "0.3s",
                    transform: hover ? "scale(1.1)" : "scale(1.2)",
                  }}
                />
              </Box>
            }
            onClick={handleOpen}
          />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Text in a modal
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
              </Typography>
            </Box>
          </Modal>
        </Grid>
        <Grid item xl={8} lg={7} md={8}></Grid>
      </Grid>
    </Stack>
  );
}
