import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import AOS from "aos";
import "aos/dist/aos.css";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
AOS.init();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const firebaseConfig = {
  apiKey: "AIzaSyDqGBiwRtrWRpIJuhNiKNYfrgZ9-D3L22E",
  authDomain: "portfolio-431ab.firebaseapp.com",
  projectId: "portfolio-431ab",
  storageBucket: "portfolio-431ab.appspot.com",
  messagingSenderId: "1065007773526",
  appId: "1:1065007773526:web:15ad6fc817e29d51f688f7",
  measurementId: "G-YD9GNC3YDM",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
let scrollRef = 0;
window.addEventListener("scroll", function () {
  scrollRef <= 10 ? scrollRef++ : AOS.refresh();
});
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
