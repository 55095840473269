import { Grid } from "@mui/material";
import React from "react";
import ProCard from "./ProCard";

const Professional = () => {
  return (
    <Grid container direction="row" justifyContent="space-between" mt={3}>
      <Grid item xl={5.5} lg={5} component="div" data-aos="zoom-out-up">
        <ProCard
          image={"./assets/riotgameslogo.jpg"}
          title="NACL"
          desc="The official “path-to-pro” ecosystem for League of Legends."
          link="https://about.rallycry.gg/blog/north-american-challengers-league"
          linkalt="Link to Rally Cry's Article on Riot Games North American Challengers League."
        />
      </Grid>
      <Grid item xl={5.5} lg={5} component="div" data-aos="zoom-out-up">
        <ProCard
          image={"./assets/nflfootball2.jpg"}
          subtitle="NFL"
          title="NFL"
          desc="The fourth annual EA SPORTS™ Madden NFL x HBCU Tournament"
          link="https://hbcutournament.nfl.com/"
          linkalt="The program is designed to reach students attending Historically Black Colleges and Universities (HBCUs) that have a passion for gaming and football."
        />
      </Grid>
      <Grid item xl={5.5} lg={5} component="div" data-aos="zoom-out-up">
        <ProCard
          image={"./assets/afg/fightericon.png"}
          title="AFG"
          desc="A competition hub for the United States Air Force and Space Force."
          link="https://airforcegaming.com/"
          linkalt="Link to Air Force Gaming which is the official gaming program and competition hub for the United States Air Force and Space Force."
        />
      </Grid>
      <Grid item xl={5.5} lg={5} component="div" data-aos="zoom-out-up">
        <ProCard
          image={"./assets/usaelicon.png"}
          title="USAEL"
          desc="To empower educators with the best tools for young people everywhere"
          link="https://www.usacademicesports.com/"
          linkalt="Link to USAEL'S Academic curriculum which are tailored to meet the unique needs and desired learning outcomes of students at different grade levels."
        />
      </Grid>
    </Grid>
  );
};

export default Professional;
