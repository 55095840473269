import { Box, Grid, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import "aos/dist/aos.css";

import LandingNameAvatar from "./LandingNameAvatar";
import LandingWorkText from "./LandingWorkText";
import LandingContactText from "./LandingContactText";
import LandingFooter from "./LandingFooter";

const LandingLeft = () => {
  const theme = useTheme();
  const isntMobile = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Grid item xl={5} lg={6} md={6} sm={6} xs={12} component="section" ml={1}>
      <Stack
        display="flex"
        direction="column"
        spacing={3}
        component="summary"
        id="summary"
        px={isntMobile ? 0 : 3}
      >
        <Stack spacing={8}>
          <LandingNameAvatar />
          <LandingWorkText />
        </Stack>
        <LandingContactText />
        <LandingFooter />
      </Stack>
      {isntMobile ? null : (
        <Box
          component="figure"
          position="relative"
          sx={{
            zIndex: -1,
            right: -65,
            bottom: 550,
            transform: "rotate(-40deg)",
          }}
        >
          <img
            style={{
              position: "absolute",
              objectFit: "contain",
            }}
            src="./assets/RobotPC-min.png"
            width="100%"
            height="auto"
            alt="A 3D robot blender image using a macbook with stickers from figma and other logos, rotated on mobile devices only. This was a 3D asset used in a free 3D illustration pack by SALY."
          />
        </Box>
      )}
    </Grid>
  );
};

export default LandingLeft;
