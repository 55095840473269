import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
const LandingBottomText = () => {
  const theme = useTheme();
  const [rallyCryHover, setRallyCryHover] = useState(false);
  const isntMobile = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Stack
      component="div"
      data-aos="fade-up"
      data-aos-once="true"
      data-aos-duration="1800"
      spacing={1}
    >
      <Typography variant="body1">
        All about creating delightful digital experiences.
      </Typography>
      <Typography
        sx={{
          display: "flex",
          direction: "row",
          textDecoration: "none",
        }}
      >
        Currently working at
        <a
          rel="noreferrer"
          aria-label="html"
          target="_blank"
          href="https://about.rallycry.gg/"
          style={{ textDecoration: "none", color: "#808080" }}
        >
          <Typography
            component={"span"}
            variant="body1"
            onMouseEnter={() => setRallyCryHover(true)}
            onMouseLeave={() => setRallyCryHover(false)}
            sx={
              rallyCryHover
                ? {
                    cursor: "pointer",
                    ml: 0.7,
                    "&:hover": {
                      transition: "all 0.3s linear",
                      color: "rgba(136, 107, 136, 0.3)",
                    },
                  }
                : { cursor: "pointer", ml: 0.7, color: "#808080" }
            }
          >
            Rally Cry
            <ArrowOutwardIcon
              sx={
                rallyCryHover
                  ? {
                      marginRight: 0.5,
                      transition: "0.3s",
                      transform: "translate(2px,-2px)",
                    }
                  : { marginRight: 0.5 }
              }
            />
          </Typography>
        </a>
        {isntMobile ? "as a Software Engineer. 👩🏻‍💻" : ""}
      </Typography>
    </Stack>
  );
};

export default LandingBottomText;
