import { Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const LandingWorkText = () => {
  return (
    <Stack spacing={2}>
      <Typography
        variant="h6"
        component="div"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-duration="900"
      >
        Work
      </Typography>
      <Stack>
        <Stack
          width={70}
          component="div"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-duration="1050"
        >
          <a
            rel="noreferrer"
            aria-label="html"
            target="_blank"
            href="https://about.rallycry.gg/"
            style={{ textDecoration: "none", color: "#808080" }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                "&:hover": {
                  color: "rgba(136, 107, 136, 0.3)",
                },
              }}
            >
              Career
            </Typography>
          </a>
        </Stack>
        <Stack
          component="div"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-duration="1200"
          width={70}
        >
          <Link
            to="projects"
            style={{
              textDecoration: "none",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                cursor: "pointer",
                "&:hover": {
                  color: "rgba(136, 107, 136, 0.3)",
                },
              }}
            >
              Projects
            </Typography>
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LandingWorkText;
